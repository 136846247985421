/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

p {
  margin-top: 0.6em;
  margin-bottom: 0.6em;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.small-button {
  width: 150px;
}
/*.container {
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
}

.container > p {
  font-size: 1rem;
}

.container > em {
  font-size: .8rem;
}*/

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  width: 100%;
  height: 150px;
}

.dropzone:focus {
  border-color: #2196f3;
}

.dropzone.disabled {
  opacity: 0.6;
}

.header .rotate img {
  width: 450px;
  height: 420px;
  object-fit: cover;
  border-radius: 30px;
}

.header .rotate {
  transform: none !important;
}

.inner-header {
  font-size: 308% !important;
  font-weight: 600 !important;
  margin-top: 85px !important;
  margin-bottom: -16px !important;
}

.inner-p {
  font-size: calc(13px + (17 - 13) * ((100vw - 300px) / (1920 - 300)));
  letter-spacing: 0;
  margin-left: 30px;
  margin-bottom: 30px;
  color: var(--secondary);
}

.slide-container {
  width: 100%;
  height: 100%;
  background-color: white;
}

.header-slide-overlay {
  content: "";
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(20, 38, 75, 0.3);
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  z-index: 1;
}
.slider-text-container {
  /*  position: absolute;
  bottom: 90px;
  top: 154px;*/
  padding: 50px;
  top: 50%;
  transform: translateY(-50%);
  position: relative;
}
.left .slider-text-container {
  background-image: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0.6) 30%,
    transparent 100%
  );
  background-image: -o-linear-gradient(
    left,
    rgba(0, 0, 0, 0.6) 30%,
    transparent 100%
  );
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.6) 30%,
    transparent 100%
  );
  background-repeat: repeat-y;
}

.right .slider-text-container {
  text-align: right;
  background-image: -webkit-linear-gradient(
    left,
    transparent 0%,
    rgba(0, 0, 0, 0.6) 70%
  );
  background-image: -o-linear-gradient(
    left,
    transparent 0%,
    rgba(0, 0, 0, 0.6) 70%
  );
  background-image: linear-gradient(
    to right,
    transparent 0%,
    rgba(0, 0, 0, 0.6) 70%
  );
  background-repeat: repeat-y;
}

.slider-text-container p {
  font-size: 22px;
}

.slide-link:hover {
  text-decoration: underline;
}

.font-size-22 {
  font-size: 22px !important;
}

.slider-box {
  border: 2px white solid;
  background: #14264b;
  cursor: pointer;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}

.slider-box.active,
.slider-box:hover {
  background: #4471cd;
}

.slider-box p {
  line-height: 22px;
  margin: 17px;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
}

.home nav {
  padding: 10px 0px;
}

header nav ul li > a {
  color: black;
}

.header.home .bg,
section#home {
  height: 100%;
}

.full-height-relative-position {
  height: 100%;
  position: relative;
  background-position: center;
  background-size: cover;
}

.full-height {
  height: 100%;
}

header nav ul li > a:hover {
  color: #4471cd !important;
}

.header .center-text {
  height: 100%;
  top: 50%;
  transform: translateY(-50%);
}

:root {
  --primary: black;
  --secondary: #4e4e4e;
  --light: #13b8ea;
  --dark: #4e56f3;
}

.no-margin {
  margin: 0;
}

.no-padding {
  padding: 0;
}

.agency.blog .blog-agency .blog-contain .img-container .blog-head {
  color: black;
}
.header .bg.agency {
  background: #0d1a37;
  background-position: right;
}
.header .title-container {
  color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.header .title-container h1,
.header .title-container p {
  color: white;
}

.agency .bg {
  height: 450px;
}

.agency h1 {
  margin-top: 17px;
}

.header .agency.bg .container {
  margin-right: 0;
  max-width: 100%;
  margin-left: 50px;
  width: 100%;
}

.services {
  background-image: url("/assets/images/contact.png");
  background-size: cover;
  background-position: center;
  color: white;
}

.services h2 {
  font-weight: 600;
  /*  color: black;*/
}

.services p {
  /*  color: black;*/
}

:root {
  --primary: #606060;
  --secondary: #606060;
  --light: #13b8ea;
  --dark: #4e56f3;
  --black: #000000c2;
}

.white-color {
  color: white !important;
}

h1,
h2,
h3,
h4,
h5,
.agency h1,
.agency h2,
.agency h3,
.agency h4,
.agency h5,
.agency h6 {
  color: var(--black);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
}

p {
  color: var(--secondary) !important;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.6em;
  letter-spacing: 0.5px;
}

h1.header-title.slide-link,
.agency.header .header-sub-text p {
  color: white !important;
}

.upcoming-actions-section {
  background-position: top right;
  background-repeat: no-repeat;
  background-size: contain;
  background: linear-gradient(
      to bottom,
      #f5f5f8 0px,
      #f5f5f8 455px,
      #3a5f88 455px,
      #3a5f88 8000px
    )
    no-repeat;
  background: linear-gradient(
      to bottom,
      #ffffff 0px,
      #ffffff 455px,
      #f5f5f8 455px,
      #f5f5f8 8000px
    )
    no-repeat;
  /*  background-size: 100% calc(100% - 455px);*/ /* Reduce height of background by 100px and width by 100px*/
  background-position: bottom;
  margin-top: 0px;
  z-index: 0;
  overflow: visible;
}

.active-actions-section {
  background: #00b4db; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to top,
    #00b4db,
    #54cce4
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #00b4db,
    #54cce4
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(
      to bottom,
      #54cce4 0px,
      #00b4db 455px,
      #ffffff 455px,
      #ffffff 8000px
    )
    no-repeat;
  margin-top: 0px;
  margin-bottom: 40px;
  z-index: 0;
  overflow: visible;
}

.results {
  background: white;
  height: auto;
  /*margin: 0px 50px 50px 0px;*/
}

.two-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.column {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 48%;
  -ms-flex: 0 0 48%;
  flex: 0 0 48%;
}

.info-section h4 {
  font-size: 18px;
  line-height: 23px;
  padding: 20px 0px;
}

.info-section p {
  font-size: 16px;
  line-height: 24px;
}

.zero-padding {
  padding: 0px;
}

.logo {
  max-width: 125px;
}
.agency p {
  font-size: 16px !important;
  font-weight: 300 !important;
  line-height: 22px;
}

.agency-para {
  padding: 10px !important;
  margin-bottom: 10px !important;
}

.boxes-row {
  justify-content: center;
}
.copyright.resume .link-horizontal.social-link ul li a i {
  color: #989a9f !important;
}

.available-for-submit {
  position: absolute;
  background-color: #14af14;
  color: white;
  font-size: 10px;
  line-height: 13px;
  padding: 4px 10px;
  margin: 10px 15px;
  border-radius: 3px;
}
.details-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.inner-page-sections-row {
  align-items: center;
}

.fade-in {
  -webkit-animation: fadeIn 0.25s ease-in-out forwards;
  animation: fadeIn 0.25s ease-in-out forwards;
}

#server-modal-title {
  font-weight: 500 !important;
  line-height: 40px;
}

#server-modal-description {
  margin-top: 20px;
  margin-bottom: 20px;
}

/*header.app1.loding-header.custom-scroll.home {
  position: fixed;
  background-color: white;
  z-index: 1;
}
.agency.bg {
  margin-top: 86px;
}*/
#sectionone,
#sectiontwo,
#sectionthree,
#sectionfour,
#sectionfive,
#sectionsix,
#sectionseven {
  padding-top: 60px;
  scroll-margin-top: 125px;
}
#search,
#draseis,
#services,
#prosexeis,
#blog,
#communication {
  scroll-margin-top: 125px;
}
#form {
  scroll-margin-top: -120px;
}
.react-confirm-alert-body > h1 {
  margin-top: 0;
  font-size: 25px;
  margin-bottom: 10px;
}
.spinner-container {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: center;
  align-items: center;
  z-index: 1000000000;
  top: 0;
  left: 0;
  margin-top: 0;
  margin-right: 0;
}
.spinner-container > div {
  margin: 0 auto;
  position: relative;
  width: 100%;
  text-align: center;
  height: 80px;
  margin-top: calc(50vh - 120px);
}
.spinner-wrapper > div {
  display: block !important;
}
.MuiTypography-h6.loading-msg {
  font-size: 20px;
  line-height: 20px;
  margin-top: 40px;
  font-weight: 400;
  color: black;
}
b,
strong {
  font-weight: 600;
}
header.app1.loding-header.custom-scroll.home {
  z-index: 600;
  width: 100%;
  position: fixed;
  background: white;
}
.action-title-section {
  padding: 23px 0;
  background-color: #0d1a37;
  width: 100%;
  position: fixed;
  top: 86px;
  z-index: 10;
  text-align: center;
}
.action-title-section h6 {
  color: white !important;
  font-weight: 500;
  font-size: 16px;
}
header nav ul li a {
  padding: 18px 12px;
}
header .responsive-btn i {
  color: #0d1a37 !important;
}
header nav ul li > a {
  color: #0d1a37;
}
.beta-icon {
  background-color: rgb(56, 142, 60) !important;
  border-radius: 5px !important;
  color: rgb(255, 255, 255) !important;
  font-size: 73% !important;
  height: 18px !important;
  margin-left: -46px !important;
  margin-top: 48px !important;
  padding: 3px 0px !important;
}
.results-header {
  background-color: #0d1a37;
  color: white;
  text-align: center;
  min-height: 75px;
  margin-top: -15px;
}

.results-header h2 {
  color: white;
  line-height: 75px;
}
